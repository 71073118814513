import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar";
import {
    Box,
    CssBaseline,
    Paper,
    ThemeProvider,
    Typography,
    createTheme,
} from "@mui/material";
import axios from "axios";
import { Project } from "./Components/ProjectCard/Declaration";
import ProjectCard from "./Components/ProjectCard";

function App() {
    const [darkTheme, setDarkTheme] = useState<"light" | "dark">("dark");

    const theme = createTheme({
        palette: {
            mode: darkTheme,
            primary: {
                main: "#5e35b1",
            },
            secondary: {
                main: "#ede7f6",
            },
        },
    });

    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        axios
            .get("https://api.otchi.ovh/portfolio/project")
            .then(({ data }) => {
                setProjects(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar theme={darkTheme} onChangeTheme={setDarkTheme} />
            <div className="App">
                <Paper elevation={3} sx={{ padding: "2rem" }}>
                    <Paper sx={{ p: "2rem", mb: "2rem" }} id="home">
                        <Typography variant="h4">Otchi</Typography>
                        <Typography variant="h6" color={"primary.main"}>
                            Adam Gragnic
                        </Typography>
                        <Typography sx={{ my: "2rem" }}>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Enim maxime alias reprehenderit harum
                            assumenda eius, nemo voluptatibus. Architecto
                            molestiae ducimus repellendus ad, quisquam, harum
                            iste accusamus officiis odit dolorem alias tempora
                            placeat ratione quam quas! Odio ratione deleniti
                            labore, ab aut adipisci? Nemo natus modi quos dicta.
                            Quidem, possimus fugit eum, libero illum inventore
                            nobis asperiores voluptates quaerat, accusamus ad?
                            Laudantium totam neque consequatur commodi quam
                            nesciunt deserunt placeat accusantium, sint error
                            quibusdam magni unde porro minima? Asperiores
                            laboriosam sapiente quam ea ullam iure non culpa
                            nulla pariatur. Atque maxime velit doloremque dolor
                            culpa at illo nam, deleniti qui expedita aliquid
                            voluptas deserunt ut hic corporis sequi alias! Totam
                            rem in quae suscipit perferendis accusamus deserunt
                            quod facilis accusantium! Delectus totam soluta nam
                            impedit rerum inventore, dolorum aspernatur deleniti
                            ipsa dolores? Porro accusantium quas numquam
                            voluptatem quo repellendus recusandae, molestias
                            vero quod eius, sapiente facilis voluptas cumque at
                            consequuntur reprehenderit deserunt sequi ea ipsum.
                            Dolore nulla reiciendis culpa veritatis ducimus
                            labore temporibus quas in quos saepe laboriosam, sed
                            perferendis ipsa expedita nihil maiores officia
                            mollitia. Quod at molestias incidunt reiciendis
                            excepturi, vero rem mollitia. Minima, dolores.
                            Repellendus rerum in totam ea laboriosam hic
                            consectetur autem, rem iste at nobis enim blanditiis
                            fugit ratione iure, illum earum odit, beatae dicta
                            assumenda quo sunt alias voluptatibus impedit! Nulla
                            magni excepturi hic nam maiores! Earum deleniti aut
                            optio corrupti cupiditate atque totam porro
                            provident beatae enim ea eligendi, error, maxime
                            ipsa veniam nostrum minima magnam facere officia.
                            Omnis tenetur enim ea accusantium ab?
                        </Typography>
                    </Paper>

                    <div id="projects">
                        <Typography variant="h3">Projets</Typography>
                        {projects.map((project, index) => (
                            <ProjectCard
                                key={project.name}
                                project={project}
                                index={index}
                            />
                        ))}
                    </div>
                </Paper>
            </div>
        </ThemeProvider>
    );
}

export default App;
