import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import { Brightness6 } from "@mui/icons-material";

type NavbarType = {
    theme: "light" | "dark";
    onChangeTheme: React.Dispatch<React.SetStateAction<"light" | "dark">>;
};

const Navbar = ({ onChangeTheme, theme }: NavbarType): JSX.Element => {
    const scroll = (id: string) => {
        const target = document.getElementById(id);
        const navbar = document.getElementsByClassName("Navbar")[0];
        if (target && navbar) {
            window.scrollTo({
                top: target.offsetTop - navbar.clientHeight * 1.2,
                behavior: "smooth",
            });
        }
    };

    return (
        <AppBar sx={{ bgcolor: "primary.main" }} className="Navbar">
            <Toolbar variant="dense">
                <Box flexGrow={1} />
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() => scroll("home")}
                >
                    Home
                </Button>
                <Box flexGrow={1} />
                <Button
                    variant="text"
                    color="secondary"
                    onClick={() => scroll("projects")}
                >
                    Projets
                </Button>
                <Box flexGrow={1} />
                <IconButton
                    onClick={() => {
                        onChangeTheme(theme === "dark" ? "light" : "dark");
                    }}
                >
                    <Brightness6 />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
