import { Box, Button, Paper, Typography } from "@mui/material";
import { Project } from "./Declaration";

type ProjectType = {
    project: Project;
    index: number;
};

const ProjectCard = ({ project, index }: ProjectType) => {
    const sideText = (
        <Box
            sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Typography variant="h5">{project.name}</Typography>
            <Typography variant="h6" color="primary.main">
                {project.langage} | {project.framework}
            </Typography>
            <Typography variant="body1">{project.description}</Typography>
            <Box display={"flex"}>
                <Button variant="outlined">Source</Button>
            </Box>
        </Box>
    );
    const sideImage = (
        <Box sx={{ width: "40%", display: "flex", justifyContent: "center" }}>
            <img
                src={`https://api.otchi.ovh/portfolio/project/img/${project.image}`}
                alt={project.name}
                style={{ width: "auto", maxHeight: "500px" }}
            />
        </Box>
    );

    return (
        <Paper
            elevation={5}
            sx={{
                p: "1rem",
                my: "1rem",
                display: "flex",
            }}
        >
            {index % 2 === 0 ? (
                <>
                    {sideText}
                    {sideImage}
                </>
            ) : (
                <>
                    {sideImage}
                    {sideText}
                </>
            )}
        </Paper>
    );
};

export default ProjectCard;
